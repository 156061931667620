import React from 'react';
import {
  Typography
} from 'antd';
import './ResultAcquisition.css';

const { Title } = Typography;

/**
 * 結果取得画面
 */
const ResultAcquisition: React.FC = () => {
  return (
    <div className="result-acquisition-container">
      <div className="result-acquisition-title">
        <Title level={4}>結果取得</Title>
      </div>
    </div>
  );
};

export default ResultAcquisition;
