import React from 'react';
import './ImageInfoHeader.css';
/**
 * 補正アップロード画面、点検結果取得画面用ヘッダー
 */

type ImageInfoProps = {
  filmingLocation: string,
  subjectOfPhotographing: string,
  date: string,
  shootingSite: string
}

const ImageInfoHeader: React.FC<ImageInfoProps> = (props) => {
  const {
    filmingLocation,
    subjectOfPhotographing,
    date,
    shootingSite,
  } = props;

  return (
    <table>
      <tbody>
        <tr>
          <td className="table-data">
            動画撮影場所（設置場所）：
            {filmingLocation}
          </td>
        </tr>
        <tr>
          <td className="table-data">
            動画撮影対象（号機）：
            {subjectOfPhotographing}
          </td>
        </tr>
        <tr>
          <td className="table-data">
            撮影日時：
            {date}
          </td>
        </tr>
        <tr>
          <td className="table-data">
            撮影部位：
            {shootingSite}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ImageInfoHeader;
