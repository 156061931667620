import React, { useEffect, useState } from 'react';
import {
  Button, Select, Table, Typography, message,
} from 'antd';
import { isEmpty } from '../../../utils/common';
import { postApiGateway } from '../../../utils/AWS/ApiGateway';
import './SelectImage.css';
import {
  EP_PATH_INFRSTECTOR_INSPECTION_PLACE_LIST,
  EP_PATH_INFRSTECTOR_INSPECTION_POSITION_LIST,
  EP_PATH_INFRSTECTOR_INSPECTION_UNIT_NO_LIST,
  EP_PATH_INFRSTECTOR_CORRECTION_PLACE_LIST,
  EP_PATH_INFRSTECTOR_CORRECTION_POSITION_LIST,
  EP_PATH_INFRSTECTOR_CORRECTION_UNIT_NO_LIST,
} from '../../../utils/AWS/EndpointPath';

/**
 * 画像取得ボタン押下時パラメータ
 * @property place - 動画撮影場所（設置場所）
 * @property unitNo - 動画撮影対象（号機）
 * @property position - 撮影日時
 * @property dateTime - 撮影部位
 * @property displayDateTime - 表示用撮影日時
 */
export type buttonProps = {
  Place: string
  UnitNo: string
  Position: string
  DateTime: string
  DisplayDateTime: string
}

/**
 * 画像取得ボタン押下時パラメータ
 */
export type getPlaceProps = {
};

/**
 * 動画撮影対象（号機）取得 パラメータ
 * @property place - 動画撮影場所（設置場所）
 */
export type getUnitNoProps = {
  place: string;
};

/**
 * 部位リスト取得パラメータ
 * @property place - 動画撮影場所（設置場所）
 * @property unitNo - 動画撮影対象（号機）
 */
export type getPositionProps = {
  place: string;
  unitNo: string;
};

/**
 * 動画撮影場所（設置場所）リスト取得 レスポンス
 * @returns placeList - 動画撮影場所（設置場所）リスト
 */
export type getPlaceJson = {
  body: {
    placeList: string[];
  };
};

/**
 * 動画撮影対象（号機）リスト取得 レスポンス
 * @returns unitNoList - 動画撮影対象（号機）リスト
 */
export type getUnitNoJson = {
  body: {
    unitNoList: string[];
  };
};

/**
 * 撮影部位リスト取得 レスポンス
 * @returns positionList - 動画撮影部位リスト:[{ 動画撮影部位, 表示用撮影日時, 撮影日時 }]
 */
export type getPositionJson = {
  body: {
    positionList: [{ position: string; datetime: string; sDaytime: string }];
  };
};

/**
 * 静止画補正/点検画像取得 - 静止画取得画面
 */
const SelectImage: React.FC<{
  screenId: string,
  onClickSearchImage: (selectedOptions: buttonProps) => void
}> = (props) => {
  const [place, setPlace] = useState(String);
  const [placeList, setPlaceList] = useState(['']);
  const [unitNoList, setUnitNoList] = useState(['']);
  const [positionList, setPositionList] = useState([{ position: '', datetime: '', sDaytime: '' }]);
  const [unitNo, setUnitNo] = useState(String);
  const [position, setPosition] = useState(String);
  const [dateTime, setDateTime] = useState(String);
  const [displayDateTime, setDisplayDateTime] = useState(String);
  const { Option } = Select;
  /**
   * 静止画取得パラメータ
   * @property screenId - 画面ID
   * @property onClickSearchImage - 画像取得ボタン押下時処理
   */
  const {
    screenId,
    onClickSearchImage,
  } = props;

  useEffect(() => {
    // 撮影場所選択時に撮影対象取得
    if (isEmpty(place)) {
      return;
    }
    getUnitNoList({
      place,
    });
    setUnitNo('');
    setPosition('');

    /**
     * 撮影対象一覧取得
     * @param data 撮影対象検索用パラメータ
     */
    async function getUnitNoList(data: getUnitNoProps) {
      try {
        const response = await postApiGateway<getUnitNoProps, getUnitNoJson>(
          screenId === 'CorrectionImage' ? EP_PATH_INFRSTECTOR_CORRECTION_UNIT_NO_LIST : EP_PATH_INFRSTECTOR_INSPECTION_UNIT_NO_LIST,
          data,
        );
        setUnitNoList(response.body.unitNoList);
      } catch (error) {
        if (error instanceof Error) {
          message.error(error.message);
        }
      }
    }
  }, [place, screenId]);

  useEffect(() => {
    // 撮影対象選択時に撮影部位取得
    if (isEmpty(place) || isEmpty(unitNo)) {
      return;
    }
    getPositionList({
      place,
      unitNo,
    });
    setPosition('');

    /**
     * 部位一覧取得
     * @param data 部位検索用パラメータ
     */
    async function getPositionList(data: getPositionProps) {
      try {
        const response = await postApiGateway<getPositionProps, getPositionJson>(
          screenId === 'CorrectionImage' ? EP_PATH_INFRSTECTOR_CORRECTION_POSITION_LIST : EP_PATH_INFRSTECTOR_INSPECTION_POSITION_LIST,
          data,
        );
        setPositionList(response.body.positionList);
      } catch (error) {
        if (error instanceof Error) {
          message.error(error.message);
        }
      }
    }
  }, [place, screenId, unitNo]);

  useEffect(() => {
    getPlaceList({});

    /**
     * 撮影場所一覧取得
     * @param data 撮影場所検索用パラメータ
     */
    async function getPlaceList(data: getPlaceProps) {
      try {
        const response = await postApiGateway<getPlaceProps, getPlaceJson>(
          screenId === 'CorrectionImage' ? EP_PATH_INFRSTECTOR_CORRECTION_PLACE_LIST : EP_PATH_INFRSTECTOR_INSPECTION_PLACE_LIST,
          data,
        );
        setPlaceList(response.body.placeList);
      } catch (error) {
        if (error instanceof Error) {
          message.error(error.message);
        }
      }
    }
  }, [screenId]);

  /**
   * 撮影場所選択時処理
   * @param value 動画撮影場所
   */
  function onPlaceChange(value: string) {
    setPlace(value);
    setUnitNo('');
  }

  /**
   * 撮影対象(号機)選択時処理
   * @param value 動画撮影対象（号機）
   */
  function onUnitNoChange(value: string) {
    setUnitNo(value);
  }

  /** 撮影部位・撮影日時選択時処理 */
  const rowSelection = {
    onChange: (selectedRowKeys: string[] | number[], selectedRows: { datetime: string, position: string, sDaytime: string }[]) => {
      setDateTime(selectedRows[0]?.sDaytime);
      setPosition(selectedRows[0]?.position);
      setDisplayDateTime(selectedRows[0]?.datetime);
    },
  };

  /** 撮影部位・撮影日時リスト用カラム */
  const columns = [
    {
      title: '撮影日時',
      dataIndex: 'datetime',
      key: 'datetime',
      width: 200,
    },
    {
      title: '撮影部位',
      dataIndex: 'position',
      key: 'position',
    },
  ];

  return (
    <div className="select-image">
      <div>
        <div>
          <div>
            <div className="select-group">
              <Typography className="select-label">
                動画撮影場所（設置場所）
              </Typography>
              <Select
                placeholder="動画撮影場所（設置場所）"
                className="select-option"
                onChange={onPlaceChange}
                disabled={placeList?.length === 0}
              >
                {placeList?.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select-group">
              <Typography className="select-label">
                動画撮影対象（号機）
              </Typography>
              <Select
                placeholder="動画撮影対象（号機）"
                className="select-option"
                onChange={onUnitNoChange}
                disabled={isEmpty(place)}
              // allowClear={true}
              >
                {unitNoList?.map((number) => (
                  <Option
                    key={number}
                    value={number}
                  >
                    {number}
                    号機
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {!isEmpty(place) && !isEmpty(unitNo) && positionList.length > 0 && (
          <Table
            className="position-list-table"
            rowKey="datetime"
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            columns={columns}
            dataSource={positionList}
            pagination={false}
            scroll={{ y: 500 }}
          />
        )}
        <div>
          <Button
            className="get-image-button"
            type="primary"
            onClick={() => onClickSearchImage({
              Place: place,
              UnitNo: unitNo,
              DateTime: dateTime,
              DisplayDateTime: displayDateTime,
              Position: position,
            })}
            disabled={isEmpty(position)}
          >
            {screenId === 'CorrectionImage' ? '静止画取得' : '点検画像取得'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectImage;
