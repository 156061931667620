import React, { useState, useEffect } from 'react';
import './ImageTable.css';
import { Checkbox } from 'antd';

/**
 * 補正アップロード画面、点検結果取得画面用画像表示テーブル
 */

/**
 * 画像表示用Props
 * @prop imgGroup 表示する画像のパスをstring型の配列で受け取る
 * @prop imageIndex ページの先頭画像の画像番号
 * @prop onCheckBox チェックボックスの表示とチェック時の処理を受け取る
 * @prop checkedImages 元々チェックがONになっている画像パスをstring型の配列で受け取る
 */
type ImageTableProps = {
  imgGroup: string[];
  imageIndex: number;
  onCheckBox?(event: string[]): void;
  checkedImages?: string[];
};

const ImageTable: React.FC<ImageTableProps> = (props) => {
  const {
    imgGroup,
    imageIndex,
    onCheckBox,
    checkedImages,
  } = props;

  const [checkedImgs, setCheckedImgs] = useState(
    checkedImages || [''],
  );

  /**
   * チェックボタン押下時
   * @param value チェックされた画像のURL
   * @param index チェックされた画像の画像番号
   */
  const onChecked = (value: string, index: number) => {
    // 画像URLを文字列分割して?以下を排除
    const checkedURL = value.split('?')[0];
    if (checkedImgs[index] === '') {
      update(index, checkedURL);
    } else {
      update(index, '');
    }
  };

  /**
   * プロップスのチェックボックス押下時の処理
   */
  useEffect(() => {
    if (onCheckBox) { onCheckBox(checkedImgs); }
  }, [checkedImgs, onCheckBox]);

  /**
   * チェック済み画像リストの更新
   * @param index 画像番号
   * @param newData 画像URL
   */
  const update = (index: number, newData: string) => {
    setCheckedImgs(
      checkedImgs.map((image, i) => (i === index ? newData : image)),
    );
  };

  return (
    <div className="overlap-images">
      <div className="overlap-contents">
        {imgGroup.length > 0
          && imgGroup.map((image, index) => (
            <div className="contents">
              <div className="image-no">
                画像
                {imageIndex + index + 1}
              </div>
              <button
                className="img-button"
                type="button"
                onClick={() => onCheckBox && onChecked(image, index + imageIndex)}
              >
                <img
                  src={image}
                  className={onCheckBox ? 'can-check-image' : 'image'}
                  alt=""
                />
              </button>
              <div className="checkbox-lapper">
                {onCheckBox && (
                  <Checkbox
                    value={image}
                    checked={checkedImgs[index + imageIndex] !== ''}
                    onClick={() => onChecked(image, index + imageIndex)}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageTable;
