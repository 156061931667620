/* eslint-disable no-irregular-whitespace */
import React from 'react';
import {
  Form,
  Upload,
  Button,
  Input,
  DatePicker,
  Popconfirm,
  message,
  Typography,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { getItem } from '../../utils/localStorageCache';

import {
  CONFIRM_VIDEO_UPLOAD,
  SUCCESS_VIDEO_UPLOAD,
  VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE,
  VALIDATE_ERROR_VIDEO_UPLOAD_CHARACTER_TYPE,
  VALIDATE_ERROR_VIDEO_UPLOAD_LENGTH,
  VALIDATE_ERROR_VIDEO_UPLOAD_FILE_TYPE,
  VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SIZE,
  VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR,
  VALIDATE_ERROR_VIDEO_UPLOAD_FILE_CONTAINS_FULLWIDTH_FILENAME,
} from '../../utils/messages';

import './VideoUpload.css';
import {
  postApiGateway,
} from '../../utils/AWS/ApiGateway';
import {
  EP_PATH_WIND_NONSTOP_UPLOAD_VIDEO_GENERATE_URL,
  EP_PATH_WIND_NONSTOP_UPLOAD_VIDEO_ADD_INDEX,
} from '../../utils/AWS/EndpointPath';

const { Title } = Typography;

/** 日時の画面表示用フォーマット */
const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
/** 日時のフォーマット */
const DATE_FORMAT = 'YYYYMMDDHHmm';
/** 時刻のフォーマット */
const TIME_FORMAT = 'HH:mm';
/** アップロードファイルの最大サイズ（バイト） */
const MAX_FILE_SIZE = 1073741824;
/** アップロードファイルのMIME TYPE */
const VIDEO_MIME_TYPES = ['video/mp4', 'video/quicktime'];
/** AWS AUTHのセッションキー */
const AUTH_RESULT_STORAGE_KEY = 'authResult';

/** Form項目 */
const FORM_ITEMS = {
  FILE: { NAME: 'uploadFile' },
  LOCATION: { NAME: 'location', LABEL: '動画撮影場所（設置場所）' },
  NUM: { NAME: 'num', LABEL: '動画撮影対象（号機）' },
  DAYTIME: { NAME: 'daytime', LABEL: '動画撮影日時' },
  PART: { NAME: 'part', LABEL: '動画部位' },
};

interface FormValues {
  uploadFile: File;
  location: string;
  num: string;
  daytime: moment.Moment;
  part: string;
}

type indexParams = {
  'location': string,
  'm_num': string,
  's_daytime': string,
  'part': string,
  'm_file': string,
  'still_state': string,
}

/**
 * ボタンの活性判定
 */
function isFileUploadButtonDisabled(fieldsError: Record<string, string[] | undefined>, fieldsValue: Record<string, Array<string> | undefined>): boolean {
  return (
    Object.keys(fieldsError).some((field) => fieldsError[field])
    || Object.values(fieldsValue).some((value) => !value)
  );
}

/**
 * ファイル名が半角英数字のみか判定
 */
function isOnlyhalfWidthAlphanumeric(str: string) {
  const halfWidthAlphanumericRegex = /^[a-zA-Z0-9]+$/;
  const filename = str.split('.')[0];
  return halfWidthAlphanumericRegex.test(filename);
}

interface Props {
  setLoadingFlag: (flag: boolean) => void;
}

/**
 * 入力Form
 * @param props Formプロパティ
 * @returns FromElement
 */
function VideoUploadForm(props: FormComponentProps & Props) {
  const [uploadButtonLoading, setUploadButtonLoading] = React.useState<boolean>(false);
  const [uploadFileName, setUploadFileName] = React.useState<
    string | undefined
  >(undefined);

  const { form } = props;
  const { resetFields, getFieldDecorator } = form;

  React.useEffect(() => {
    // Form初期化
    resetFields();
  }, [resetFields]);

  /**
   * ファイルアップロード時のバリエーション
   * - 必須チェック
   * - MIME TYPEチェック
   * - サイズチェック（最大1GB）
   * - ファイル読み込みチェック
   * @param _ rule
   * @param value 入力値（File Object）
   * @param callback コールバック関数
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateUploadFile = (_: any, value: any, callback: any) => {
    setUploadButtonLoading(true);
    if (!value) {
      callback(VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE);
      setUploadButtonLoading(false);
    }

    // ファイルタイプがmp4,movであるかチェック
    if (!VIDEO_MIME_TYPES.includes(value.type)) {
      callback(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_TYPE);
      setUploadButtonLoading(false);
    }

    // ファイルサイズ上限チェック
    if (MAX_FILE_SIZE < value.size) {
      callback(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SIZE);
      setUploadButtonLoading(false);
    }

    // 動画ファイル名に使われている文字が半角英数字のみかチェック
    if (!isOnlyhalfWidthAlphanumeric(value.name)) {
      callback(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_CONTAINS_FULLWIDTH_FILENAME);
      setUploadButtonLoading(false);
    }

    // 再生できるか確認
    const video = document.createElement('video');
    const fileURL = URL.createObjectURL(value);
    video.src = fileURL;

    // 再生できるときの処理
    video.onloadedmetadata = () => {
      callback();
      setUploadButtonLoading(false);
    };

    // 再生できないときの処理
    video.onerror = () => {
      callback(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_TYPE);
      setUploadButtonLoading(false);
    };
  };

  /**
   * アップロード処理イベントハンドラ
   */
  const handleConfirm = () => {
    form.validateFields(async (errors, values) => {
      if (!errors) {
        props.setLoadingFlag(true);
        try {
          const { awsIdToken } = getItem(AUTH_RESULT_STORAGE_KEY);
          const directoryName = createDirectoryName(values);

          const params: indexParams = createIndex(values, awsIdToken);

          // 共通部品でAPIGatewayへリクエスト送信
          const { errorMessage } = await postApiGateway<
            { objectKey: string; index: indexParams },
            { errorMessage: string }
          >(EP_PATH_WIND_NONSTOP_UPLOAD_VIDEO_ADD_INDEX, {
            objectKey: `${directoryName}/${values.uploadFile.name}`,
            index: params,
          });
          // eslint-disable-next-line no-empty
          if (errorMessage) {
            message.error(errorMessage);
          } else {
            const { presignedUrl } = await postApiGateway<
              { objectKey: string; index: indexParams },
              { presignedUrl: string }
            >(EP_PATH_WIND_NONSTOP_UPLOAD_VIDEO_GENERATE_URL, {
              objectKey: `${directoryName}/${values.uploadFile.name}`,
              index: params,
            });
            // 署名URLに対してアップロード処理
            await fetch(presignedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': values.uploadFile.type },
              body: values.uploadFile,
            });
            message.success(SUCCESS_VIDEO_UPLOAD);
          }
        } catch (error: any) {// eslint-disable-line
          message.error(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR);
        } finally {
          props.setLoadingFlag(false);
        }
      }
    });
  };

  /**
   * ファイル削除処理イベントハンドラ
   */
  const handleDeleteFile = () => {
    setUploadFileName(undefined);
    form.resetFields([FORM_ITEMS.FILE.NAME]);
  };

  /**
   * アップロードファイルのForm入力値取得処理
   * @param e 入力値
   * @returns {File} 入力ファイル
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getInputFile = (e: any) => {
    setUploadFileName(e?.file.name);
    return e?.file;
  };

  /**
   * Indexファイルの作成
   * @param values Form入力値
   * @param idToken AWS IdToken
   * @returns {File} Indexファイル
   */
  const createIndex = (values: FormValues, idToken: string) => {
    // const fileName = values.uploadFile.name.split('.').slice(0, -1).join('.');

    const index: indexParams = {
      location: values.location,
      m_num: values.num,
      s_daytime: values.daytime.format(DATE_FORMAT),
      part: values.part,
      m_file: values.uploadFile.name,
      still_state: '0',
    };
    return index;
  };

  /**
   * S3格納先のディレクトリ名作成
   * @param values Form入力値
   * @returns {string} ディレクトリ名
   */
  const createDirectoryName = (values: FormValues) => `${values.location}_${values.num}_${values.daytime.format(DATE_FORMAT)}_${values.part}`;

  return (
    <>
      <div className="video-upload-title">
        <Title level={4}>動画アップロード</Title>
      </div>
      <div className="video-upload-container">
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item wrapperCol={{ span: 24 }} style={{ marginLeft: '10%' }}>
            {getFieldDecorator(FORM_ITEMS.FILE.NAME, {
              valuePropName: 'file',
              getValueFromEvent: getInputFile,
              rules: [
                {
                  validator: validateUploadFile,
                },
              ],
            })(
              <Upload showUploadList={false} beforeUpload={() => false} accept=".mov, .mp4">
                <Button
                  disabled={uploadFileName !== undefined}
                  loading={uploadButtonLoading}
                >
                  アップロード動画指定
                </Button>
              </Upload>,
            )}
            {uploadFileName && (
              <span style={{ marginLeft: '30px' }}>
                {uploadFileName}
                <Button type="link" icon="delete" onClick={handleDeleteFile} />
              </span>
            )}
          </Form.Item>
          <Form.Item label={FORM_ITEMS.LOCATION.LABEL}>
            {getFieldDecorator(FORM_ITEMS.LOCATION.NAME, {
              rules: [
                {
                  required: true,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE,
                },
                // eslint-disable-next-line no-control-regex
                {
                  pattern: /^[^\\x01-\x7E\uFF61-\uFF9F]+$/,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_CHARACTER_TYPE,
                },
                { max: 40, message: VALIDATE_ERROR_VIDEO_UPLOAD_LENGTH },
              ],
            })(<Input placeholder={FORM_ITEMS.LOCATION.LABEL} />)}
            <span className="example-container">
              例：秋田県　男鹿半島（全角のみ４０文字）
            </span>
          </Form.Item>
          <Form.Item label={FORM_ITEMS.NUM.LABEL}>
            {getFieldDecorator(FORM_ITEMS.NUM.NAME, {
              rules: [
                {
                  required: true,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE,
                },
                {
                  pattern: /^[0-9]+$/,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_CHARACTER_TYPE,
                },
                { max: 2, message: VALIDATE_ERROR_VIDEO_UPLOAD_LENGTH },
              ],
            })(<Input placeholder={FORM_ITEMS.NUM.LABEL} />)}
            <span className="example-container">
              例：1 （1、2、3等数値2桁）
            </span>
          </Form.Item>
          <Form.Item label={FORM_ITEMS.DAYTIME.LABEL}>
            {getFieldDecorator(FORM_ITEMS.DAYTIME.NAME, {
              rules: [
                {
                  required: true,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE,
                },
              ],
            })(
              <DatePicker
                format={DISPLAY_DATE_FORMAT}
                showTime={{
                  format: TIME_FORMAT,
                }}
                placeholder={FORM_ITEMS.DAYTIME.LABEL}
              />,
            )}
            <span className="example-container">
              例：2024-02-01 10:00 （カレンダから入力）
            </span>
          </Form.Item>
          <Form.Item label={FORM_ITEMS.PART.LABEL}>
            {getFieldDecorator(FORM_ITEMS.PART.NAME, {
              rules: [
                {
                  required: true,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE,
                },
                // eslint-disable-next-line no-control-regex
                {
                  pattern: /^[^\\x01-\x7E\uFF61-\uFF9F]+$/,
                  message: VALIDATE_ERROR_VIDEO_UPLOAD_CHARACTER_TYPE,
                },
                { max: 10, message: VALIDATE_ERROR_VIDEO_UPLOAD_LENGTH },
              ],
            })(<Input placeholder={FORM_ITEMS.PART.LABEL} />)}
            <span className="example-container">
              例：ＬＥ、ＴＥ、ＰＳ、ＳＳ （全角のみ）
            </span>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }} style={{ marginLeft: '10%' }}>
            <Popconfirm title={CONFIRM_VIDEO_UPLOAD} onConfirm={handleConfirm} disabled={isFileUploadButtonDisabled(form.getFieldsError(), form.getFieldsValue())}>
              <Button htmlType="submit" disabled={isFileUploadButtonDisabled(form.getFieldsError(), form.getFieldsValue())}>アップロード実施</Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

/**
 * 動画アップロード画面
 */
const VideoUpload = Form.create<FormComponentProps & Props>()(VideoUploadForm);

export default VideoUpload;
