import React from 'react';
import {
  Typography
} from 'antd';
import './ImageAcquisition.css';

const { Title } = Typography;

/**
 * 画像取得画面
 */
const ImageAcquisition: React.FC = () => {
  return (
    <div className="image-acquisition-container">
      <div className="image-acquisition-title">
        <Title level={4}>画像取得</Title>
      </div>
    </div>
  );
};

export default ImageAcquisition;
