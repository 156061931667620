import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, message, Modal, Pagination, Typography,
} from 'antd';
import SelectImage from '../Common/SelectImage/SelectImage';
import ImageTable from '../Common/ImageTable/ImageTable';
import ImageInfoHeader from '../Common/ImageInfoHeader/ImageInfoHeader';
import './CorrectionImage.css';
import {
  VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR, M1001, M1002, M1E01, M1E02,
} from '../../utils/messages';
import {
  EP_PATH_INFRSTECTOR_CORRECTION_IMAGE_LIST,
  EP_PATH_INFRSTECTOR_CORRECTION_CHECK_LIST,
  EP_PATH_INFRSTECTOR_CORRECTION_STATUS_CHECK,
  EP_PATH_INFRSTECTOR_CORRECTION_UPLOAD,
  EP_PATH_INFRSTECTOR_CORRECTION_UPLOAD_STATUS,
} from '../../utils/AWS/EndpointPath';
import { postApiGateway } from '../../utils/AWS/ApiGateway';
import { awsConfig } from '../../sdf-env.json';

const { Title } = Typography;
/**
 * 静止画取得パラメータ
 * @property Place - 動画撮影場所（設置場所）
 * @property UnitNo - 動画撮影対象（号機）
 * @property DateTime - 撮影日時
 * @property Position - 撮影部位
 * @property PageNum - ページ番号
 */
export type getImagesProps = {
  Place: string,
  UnitNo: string,
  DateTime: string,
  Position: string,
  PageNum: number
}

/**
 * チェック済静止画取得パラメータ
 * @property Place - 動画撮影場所（設置場所）
 * @property UnitNo - 動画撮影対象（号機）
 * @property DateTime - 撮影日時
 * @property Position - 撮影部位
 */
export type getCheckImagesProps = {
  Place: string,
  UnitNo: string,
  DateTime: string,
  Position: string
}

/**
 * 確認結果登録パラメータ
 * @property place - 動画撮影場所（設置場所）
 * @property unitNo - 動画撮影対象（号機）
 * @property dateTime - 撮影日時
 * @property position - 撮影部位
 * @property checked_imgs - チェック済画像リスト
 */
export type uploadProps = {
  input: string,
  stateMachineArn: string,
}

/**
 * 確認結果登録ステータス取得パラメータ
 * @property arn - arn
 */
export type uploadStatusProps = {
  executionArn: string,
  output: string,
  status: string,
}

/**
 * ステータス取得パラメータ
 * @property place - 動画撮影場所（設置場所）
 * @property unitNo - 動画撮影対象（号機）
 * @property dateTime - 撮影日時
 * @property position - 撮影部位
 */
export type statusCheckProps = {
  place: string,
  unitNo: string,
  dateTime: string,
  position: string,
}

/**
 * 静止画取得レスポンス
 * @returns imgList - 画像リスト
 * @returns totalImgNum - 画像総数
 */
export type getCorrectionImgJson = {
  body: {
    imgList: string[],
    totalImgNum: number,
  }
}

/**
 * チェック済静止画取得レスポンス
 * @returns checkedImgsUrl - チェック済画像リスト
 */
export type getCorrectionCheckImgJson = {
  body: {
    checkedImgsUrl: string[],
  }
}

/**
 * ステータス取得レスポンス
 * @returns status - ステータス
 */
export type statusCheckJson = {
  body: {
    status: string
  }
}

/**
 * 静止画補正 ステータス取得Json
 * @returns
 */
export type uploadStatusJson = {
  body: {
  }
}

interface Props {
  setLoadingFlag: (flag: boolean) => void;
}

/**
 * 静止画補正画面
 */
const CorrectionImage: React.FC<Props> = (props) => {
  const [showSelectImage, setShowSelectImage] = useState(true);
  const [place, setPlace] = useState(String);
  const [unitNo, setUnitNo] = useState(String);
  const [position, setPosition] = useState(String);
  const [dateTime, setDateTime] = useState(String);
  const [displayDateTime, setDisplayDateTime] = useState(String);
  const [totalPage, setTotalPage] = useState(Number);
  const [selGroup, setSelGroup] = useState(['']);
  const [imageList, setImageList] = useState(['']);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkImages, setCheckImages] = useState(['']);
  const [searchInfo, setSearchInfo] = useState<getImagesProps>({
    Place: '',
    UnitNo: '',
    DateTime: '',
    Position: '',
    PageNum: 1,
  });
  const COLMUN = 100;

  /**
   * 静止画取得ボタン押下時処理
   */
  const onClickSearchImage = useCallback((buttonProps) => {
    const {
      Place, UnitNo, Position, DateTime, DisplayDateTime,
    } = buttonProps;
    setSearchInfo({
      Place,
      UnitNo,
      Position,
      DateTime,
      PageNum: 1,
    });
    setPlace(Place);
    setUnitNo(UnitNo);
    setPosition(Position);
    setDateTime(DateTime);
    const data: statusCheckProps = {
      place: Place,
      unitNo: UnitNo,
      dateTime: DateTime,
      position: Position,
    };
    statusCheck(data);
    setDisplayDateTime(DisplayDateTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * チェック済静止画取得API呼び出し
   * @param Place - 動画撮影場所（設置場所）
   * @param UnitNo - 動画撮影対象（号機）
   * @param Position - 撮影日時
   * @param DateTime - 撮影部位
   */
  const getCheckImgs = (Place: string, UnitNo: string, Position: string, DateTime: string) => {
    const data: getCheckImagesProps = {
      Place,
      UnitNo,
      DateTime,
      Position,
    };
    callGetCheckImageApi(data);
  };

  /**
   * チェック済静止画取得API呼び出し
   * @param data - チェック済静止画取得パラメータ
   */
  async function callGetCheckImageApi(data: getCheckImagesProps) {
    try {
      await postApiGateway<getCheckImagesProps, getCorrectionCheckImgJson>(
        EP_PATH_INFRSTECTOR_CORRECTION_CHECK_LIST, data,
      ).then((e) => {
        if (e.body !== undefined) {
          setImageList(e.body.checkedImgsUrl);
          setShowSelectImage(false);
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    }
  }

  /**
   * 画像URL・総ページ数取得
   * @param data 画像検索用パラメータ
   */
  async function getImgUrl(data: getImagesProps) {
    try {
      await postApiGateway<getImagesProps, getCorrectionImgJson>(
        EP_PATH_INFRSTECTOR_CORRECTION_IMAGE_LIST, data,
      ).then((e) => {
        if (e.body !== undefined) {
          setSelGroup(e.body.imgList);
          setTotalPage(e.body.totalImgNum);
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    }
  }

  useEffect(() => {
    const data: getImagesProps = {
      Place: searchInfo.Place,
      UnitNo: searchInfo.UnitNo,
      DateTime: searchInfo.DateTime,
      Position: searchInfo.Position,
      PageNum: searchInfo.PageNum,
    };
    if (searchInfo.Position !== '') {
      getImgUrl(data);
    }
  }, [searchInfo]);

  /**
   * ページボタン押下時
   * @param page ページ番号
   */
  const onClickPage = (page: number) => {
    setSelGroup([]);
    setSearchInfo({ ...searchInfo, PageNum: page });
    setCurrentPage(page);
  };

  /**
   * ステータスチェック
   * @param data ステータス取得パラメータ
   */
  async function statusCheck(data: statusCheckProps) {
    try {
      const response = await postApiGateway<statusCheckProps, statusCheckJson>(
        EP_PATH_INFRSTECTOR_CORRECTION_STATUS_CHECK,
        data,
      );
      if (response.body.status === '0') {
        message.error(M1E02);
      } else {
        getCheckImgs(data.place, data.unitNo, data.position, data.dateTime);
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    }
  }

  /**
   * 確認結果登録ボタン押下時処理
   */
  const onClickCollectionUpload = () => {
    const requestCheckImages: string[] = [];
    checkImages.forEach((e) => {
      if (e !== '') { requestCheckImages.push(e); }
    });
    if (requestCheckImages.length === 0) {
      message.error(M1E01);
    } else {
      setCheckImages(requestCheckImages);
      setConfirmModalVisible(true);
    }
  };

  /** チェックボックス操作時処理 */
  const onChecked = useCallback((image: string[]) => {
    setCheckImages(image);
  }, []);

  /** 登録確認ダイアログOK押下時処理 */
  const handleOk = useCallback(async () => {
    setConfirmModalVisible(false);
    props.setLoadingFlag(true);
    const jsonData = {
      place, unitNo, position, dateTime, checkedImgs: checkImages,
    };
    const { region, accountId, stateMachine } = awsConfig;
    const { putCorrection } = stateMachine;
    const executionArn = await correctionUpload({
      input: JSON.stringify(jsonData),
      stateMachineArn: `arn:aws:states:${region}:${accountId}:stateMachine:${putCorrection}`,
    });
    if (executionArn !== undefined) {
      statuscorrectionUpload(executionArn);
    }
  }, [place, unitNo, position, dateTime, checkImages]);

  /**
   * 確認結果登録
   * @param data 確認結果登録パラメータ
   */
  async function correctionUpload(data: uploadProps) {
    try {
      const arn = await postApiGateway<uploadProps, uploadStatusProps>(
        EP_PATH_INFRSTECTOR_CORRECTION_UPLOAD,
        data,
      );
      return arn.executionArn;
    } catch (e) {
      props.setLoadingFlag(false);
      message.error(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR);
      return undefined;
    }
  }

  /**
   * 確認結果登録状態を取得する。
   * @param arn 確認結果登録Lambdaの実行ID
   * @returns 確認結果登録実行後のステータスコード
   */
  // eslint-disable-next-line no-async-promise-executor
  const statuscorrectionUpload = async (arn: string): Promise<uploadStatusProps> => new Promise(async (resolve, reject) => {
    let status;
    const scope = {
      executionArn: arn,
      output: '',
      status: '',
    };
    try {
      // 10秒ごとに実行
      const getStatus = setInterval(async () => {
        status = await postApiGateway<uploadStatusProps, uploadStatusProps>(
          EP_PATH_INFRSTECTOR_CORRECTION_UPLOAD_STATUS,
          scope,
        );
        if (status.output !== undefined) {
          // ステータスコードを取得
          const dict = JSON.parse(status.output);
          if (dict != null && 'statusCode' in dict) {
            const { statusCode } = dict;
            props.setLoadingFlag(false);
            message.success(M1002);
            clearInterval(getStatus);
            resolve(statusCode);
          } else {
            clearInterval(getStatus);
            props.setLoadingFlag(false);
            message.error(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR);
            const { statusCode } = JSON.parse('{"statusCode": 400}');
            resolve(statusCode);
          }
        } else if ('status' in status && status.status !== 'RUNNING') {
          // 確認結果登録実行以前のエラー
          props.setLoadingFlag(false);
          message.error(VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR);
          clearInterval(getStatus);
          const { statusCode } = JSON.parse('{"statusCode": 400}');
          resolve(statusCode);
        } else {
          console.log('確認結果登録 実行中');
        }
      }, 10000);
    } catch (e) {
      props.setLoadingFlag(false);
      console.error(e);
      reject(e);
    }
  });

  /** 登録確認ダイアログキャンセル押下時処理 */
  const handleCancel = useCallback(() => {
    setConfirmModalVisible(false);
  }, []);

  return (
    <div className="correction-image-container">
      <div className="correction-image-title">
        <Title level={4}>静止画補正</Title>
      </div>
      {showSelectImage
        && (
          <SelectImage
            screenId="CorrectionImage"
            onClickSearchImage={onClickSearchImage}
          />
        )}
      {!showSelectImage
        && (
          <>
            <div className="image-table-wrapper">
              <div className="image-info-header">
                <ImageInfoHeader
                  filmingLocation={place}
                  subjectOfPhotographing={unitNo}
                  date={displayDateTime}
                  shootingSite={position}
                />
              </div>
              <ImageTable
                imgGroup={selGroup}
                imageIndex={(currentPage - 1) * COLMUN}
                onCheckBox={onChecked}
                checkedImages={imageList}
              />
              <Pagination
                className="pagination"
                size="small"
                total={totalPage}
                defaultPageSize={COLMUN}
                onChange={onClickPage}
                current={currentPage}
              />
              <Button
                className="upload-button"
                type="primary"
                onClick={() => onClickCollectionUpload()}
                disabled={checkImages.length === 0}
              >
                確認結果登録
              </Button>
            </div>
          </>
        )}
      <div>
        <Modal
          className="confirm-modal"
          visible={confirmModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>{M1001}</p>
        </Modal>
      </div>
    </div>
  );
};

export default CorrectionImage;
