import {
  Asset, AssetListScope,
  CogniteInternalId,
  FileChangeUpdate, FileInfo,
  InternalId, ItemsWrapper,
} from '@cognite/sdk';

import { EP_PATH_PROJECTS_LIST, EP_PATH_AUTHORITY_LIST, EP_PATH_ROAD_AUTHORITY } from './EndpointPath';
import {
  ResponseCogniteItems,
  ResponseCogniteError,
  postApiGateway,
  ResponseFunctionAuthentication,
  RequestFunctionAuthentication,
  PostTenantInfo,
  ResponseTenantInfo,
  PostRoadProject,
  ResponseRoadProject,
} from './ApiGateway';
import { ResourceType } from '../common/SDFDataType';
import {
  getManagedFacilityFilesIcon,
  retrieveManagedFacilityFile,
  updateManagedFacilityFile,
  deleteManagedFacilityFile,
  getManagedFacilityFileDownloadUrl,
} from '../File/ManagedFacilityFile';
import {
  getFacilityFilesIcon,
  retrieveFacilityFile,
  updateFacilityFile,
  deleteFacilityFile,
  getFacilityFileDownloadUrl,
} from '../File/FacilityFile';
import {
  deleteTeacherDataFile,
  getTeacherDataFilesIcon,
  retrieveTeacherDataFile,
  updateTeacherDataFile,
} from '../File/TeacherDataFile';
import { getLearningProjectFilesIcon, retrieveLearningProjectFile } from '../File/LearningProjectFile';
import { getAiDetectResultFilesIcon, retrieveAiDetectResultFile } from '../File/AIDetectResultFile';
import { loadAllManagedFacilityFromCDFByIds, loadAllManagedFacilityFromCDFByScope } from '../Asset/ManagedFacilityAsset';
import { loadAllDetectionResultsFromCDFByIds, loadAllDetectionResultsFromCDFByScope } from '../Asset/DetectionResultAsset';
import { loadAllSolarSiteFromCDFByIds, loadAllSolarSiteFromCDFByScope } from '../Asset/SolarSiteAsset';
import { retrieveFinancialReportFile } from '../File/FinancialReportFile';

/**
 * リソース種別によってアセット情報を取得する。（ID指定）
 * @param resourceType リソース種別
 * @param ids IDリスト
 * @returns アセット情報
 */
const retrieveAsset = async (
  resourceType: ResourceType,
  ids: InternalId[],
): Promise<ItemsWrapper<Asset[]>> => {
  let assets: ItemsWrapper<Asset[]>;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      assets = await loadAllManagedFacilityFromCDFByIds(ids);
      break;
    case ResourceType.AIDetectResult:
      assets = await loadAllDetectionResultsFromCDFByIds(ids);
      break;
    case ResourceType.SolarSite:
      assets = await loadAllSolarSiteFromCDFByIds(ids);
      break;
    default:
      assets = { items: [] };
      break;
  }

  return assets;
};

/**
 * リソース種別によってアセット情報を取得する。
 * @param resourceType リソース種別
 * @param scope アセット検索条件
 * @returns アセット情報
 */
const getAssetList = async (
  resourceType: ResourceType,
  scope: AssetListScope,
): Promise<Asset[]> => {
  let assets: Asset[];
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      assets = await loadAllManagedFacilityFromCDFByScope(scope);
      break;
    case ResourceType.AIDetectResult:
      assets = await loadAllDetectionResultsFromCDFByScope(scope);
      break;
    case ResourceType.SolarSite:
      assets = await loadAllSolarSiteFromCDFByScope(scope);
      break;
    default:
      assets = [];
      break;
  }

  return assets;
};

/**
 * リソース種別によってファイル情報を取得する。(ID指定)
 * @param resourceType リソース種別
 * @param id ID
 * @returns ファイル情報
 */
const retrieveFile = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<FileInfo | undefined> => {
  let fileInfo: FileInfo | undefined;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      fileInfo = await retrieveManagedFacilityFile(id);
      break;
    case ResourceType.Facility:
      fileInfo = await retrieveFacilityFile(id);
      break;
    case ResourceType.Training:
      fileInfo = await retrieveTeacherDataFile(id);
      break;
    case ResourceType.LearningProject:
      fileInfo = await retrieveLearningProjectFile(id);
      break;
    case ResourceType.AIDetectResult:
      fileInfo = await retrieveAiDetectResultFile(id);
      break;
    case ResourceType.SolarSite:
      fileInfo = await retrieveFinancialReportFile(id);
      break;
    default:
      break;
  }

  return fileInfo;
};

/**
 * リソース種別によってファイルアイコンを更新する。
 * @param resourceType リソース種別
 * @param id ID
 * @returns ファイルアイコン
 */
const getFilesIcon = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<string | undefined> => {
  let iconUrl: string | undefined;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      iconUrl = await getManagedFacilityFilesIcon(id);
      break;
    case ResourceType.Facility:
      iconUrl = await getFacilityFilesIcon(id);
      break;
    case ResourceType.Training:
      iconUrl = await getTeacherDataFilesIcon(id);
      break;
    case ResourceType.LearningProject:
      iconUrl = await getLearningProjectFilesIcon(id);
      break;
    case ResourceType.AIDetectResult:
      iconUrl = await getAiDetectResultFilesIcon(id);
      break;
    default:
      iconUrl = undefined;
      break;
  }

  return iconUrl;
};

/**
 * リソース種別によってファイルダウンロードURLを取得する。
 * @param resourceType リソース種別
 * @param id ID
 * @returns ファイルアイコン
 */
const getDownloadUrl = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<string> => {
  let downloadUrl: string;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      downloadUrl = await getManagedFacilityFileDownloadUrl(id);
      break;
    case ResourceType.Facility:
      downloadUrl = await getFacilityFileDownloadUrl(id);
      break;
    default:
      downloadUrl = '';
      break;
  }

  return downloadUrl;
};

/**
 * リソース種別によってファイル情報を更新する。
 * @param resourceType リソース種別
 * @param changes ファイル更新情報
 * @returns 更新後のファイル情報
 */
const updateFile = async (
  resourceType: ResourceType,
  changes: FileChangeUpdate,
): Promise<ResponseCogniteItems<FileInfo[]>> => {
  let updatedFiles: ResponseCogniteItems<FileInfo[]>;
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      updatedFiles = await updateManagedFacilityFile(changes);
      break;
    case ResourceType.Facility:
      updatedFiles = await updateFacilityFile(changes);
      break;
    case ResourceType.Training:
      updatedFiles = await updateTeacherDataFile(changes);
      break;
    default:
      updatedFiles = { items: [] };
      break;
  }

  return updatedFiles;
};

/**
 * リソース種別によってファイルを削除する。
 * @param resourceType リソース種別
 * @param id ファイルID
 */
const deleteFile = async (
  resourceType: ResourceType,
  id: CogniteInternalId,
): Promise<void> => {
  switch (resourceType) {
    case ResourceType.ManagedFacility:
      await deleteManagedFacilityFile(id);
      break;
    case ResourceType.Facility:
      await deleteFacilityFile(id);
      break;
    case ResourceType.Training:
      await deleteTeacherDataFile(id);
      break;
    default:
      break;
  }
};

/**
 * テナントにアクセス権限を持っているか判定する。
 * @param tenant テナント名
 * @returns アクセス権限がある場合: 権限グループ, ない場合: undefined
 */
const hasPermissionToProject = async (tenant: string): Promise<ResponseTenantInfo> => {
  let authorityGroup;
  const scope = {
    project: tenant,
  };

  try {
    authorityGroup = await postApiGateway<PostTenantInfo, string[]>(
      EP_PATH_PROJECTS_LIST,
      scope,
    );
  } catch {
    return undefined;
  }

  return authorityGroup;
};

/**
 * テナント上での各機能への各種権限を取得する。
 * @param tenant テナント名
 * @returns ユーザロール、各画面/機能に対する権限
 */
const getPermissionToFunction = async (tenant: string):
  Promise<ResponseFunctionAuthentication | ResponseCogniteError> => {
  const scope = {
    project: tenant,
  };
  const getAuthentication = await postApiGateway<
    RequestFunctionAuthentication,
    ResponseFunctionAuthentication | ResponseCogniteError
  >(EP_PATH_AUTHORITY_LIST, scope);

  return getAuthentication;
};

/**
 * ユーザー権限に対する道路プロジェクトを取得する。
 * @param tenant テナント名
 * @returns ユーザー権限に対する道路プロジェクト
 */
const getRoadProject = async (tenant: string): Promise<ResponseRoadProject> => {
  let roadProject;
  const scope = {
    project: tenant,
  };

  try {
    roadProject = await postApiGateway<PostRoadProject, string[]>(
      EP_PATH_ROAD_AUTHORITY,
      scope,
    );
  } catch {
    return undefined;
  }

  return roadProject;
};

export {
  retrieveAsset,
  getAssetList,
  retrieveFile,
  getFilesIcon,
  getDownloadUrl,
  updateFile,
  deleteFile,
  hasPermissionToProject,
  getPermissionToFunction,
  getRoadProject,
};
