import React from 'react';
import {
  Route,
  HashRouter,
  Link,
  // Redirect
} from 'react-router-dom';
import { Layout, Menu, Spin, Popconfirm, Modal, Button } from 'antd';
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from "history";

import Home from '../Home/Home';
import VideoUpload from '../infratectorCore/VideoUpload';
import ImageAcquisition from '../infratectorCore/ImageAcquisition';
import ResultAcquisition from '../infratectorCore/ResultAcquisition';
import CorrectionImage from '../infratectorCore/CorrectionImage';
import InspectionResultsAcquisition from '../infratectorCore/InspectionResultsAcquisition';
import { setStorageMenuKey, getStorageMenuKey, removeStorageData } from '../../utils/storageCommon';
import { sdfLogOut } from '../../utils/common';
import { LOGOUT_CONFIRM_MESSAGE } from '../../utils/messages/index'
import {
  roadManagementUrl,
  roadManagementDashboardUrl
} from '../../utils/Links';
import {
  // MenuRow,
  SubMenuRow,
  MenuIcon,
  MenuText
} from './MenuParts';
import './Main.css'

const { Sider, Content, Footer } = Layout;
const PATH_OF_OSS_LIST = '/wind_nonstop-notice.txt';
/**
 * Menu部component
 * @property {object} client CogniteClient
 */
class Main extends React.Component {

  state = {
    collapsed: false,
    selectedPage: 1,
    selectedMenuKey: "1",
    loadingFlag: false,
    authorization: undefined,
    isModal: false,
  };

  /**
   * Menu展開時(下の">"ボタン)のイベントハンドラ
   * @param {boolean} collapsed
   */
  onCollapse = collapsed => {
    this.setState({
      collapsed
    });
  };

  /**
   * Menuクリック時のイベントハンドラ
   * 選択したMenuのkeyをストレージに格納
   * @param {object} item 選択したMenu Item
   */
  onClickMenu = (item) => {
    this.setSelectedMenuKey(item.key);
  }

  /**
   * 風力・太陽光メニュークリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindow = (url) => {
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  }

  /**
   * 道路メニュー（道路運営管理デジタルツイン）クリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindowRoad = (url, json) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomStr = '';
    for (let i = 0; i < 40; i++) {
      randomStr += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const encodedStr = btoa(randomStr);
    window.localStorage.setItem(json, encodedStr);
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  }

  /**
   * 道路メニュー（ダッシュボード）クリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindowRoadDashboard = (url) => {
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  }

  /**
   * ログアウトボタンクリック時のイベントハンドラ
   */
  onClickLogOut = async () => {
    sdfLogOut();
    await Auth.signOut();
  }

  /**
   * MenuKeyの設定
   * @param {string} keyMenuKey
   */
  setSelectedMenuKey = (key) => {
    setStorageMenuKey(key);
    this.setState({
      selectedMenuKey: key
    });
  }

  /**
   * ページ番号の設定
   * @param {number} num 選択しているページ数
   */
  setSelectedPage = (num) => {
    this.setState({
      selectedPage: num
    });
  }

  /**
   * ロードフラグの設定
   * @param {boolean} flag Loading Flag (true: Start Loading / false:Loading Finish)
   */
  setLoadingFlag = (flag) => {
    this.setState({
      loadingFlag: flag
    });
  }

  /**
   * MenuKeyの取得
   * @returns {string} MenuKey
   */
  getMenuKey = () => {
    const history = createBrowserHistory();
    const hash = history.location.hash;

    if (hash === "#/") {
      return "1";
    }
    return getStorageMenuKey();
  }

  /**
   * Versionクリック時のイベントハンドラ
   */
  onVersionClick = () => {
    this.setState({ isModal: true });
  }

  /**
   * Modalキャンセル時のイベントハンドラ
   */
  onCancelModal = () => {
    this.setState({ isModal: false });
  }



  /**
   * MenuItemとRoute、FooterMenuの作成
   * @returns {object} MenuItemとRouteの配列オブジェクト、FooterMenuオブジェクト
   */
  generateMenuItemsWithRoute = () => {
    const { collapsed, authorization } = this.state;

    if (!authorization) return { menuItems: [], routes: [], footerMenu: undefined };
    const { accessibleScreenList } = authorization;
    const isReadOnly = authorization.userRole === 'readOnly';

    const menuItems = [];
    const routes = [];

    routes.push(
      <Route key="home-key" exact path='/' render={() => (
        <Home client={this.props}
          setLoadingFlag={this.setLoadingFlag}
        />
      )} />
    );

    // 道路運営管理
    const isDisplayDigitalTwin = accessibleScreenList.includes('roadManagement');
    if (isDisplayDigitalTwin) {
      const subMenuItems = [];

      if (isDisplayDigitalTwin) {
        // 道路運営管理デジタルツイン
        let initJson;
        if (this.props.roadproject) {
          initJson = this.props.roadproject
        } else {
          initJson = "sample"
        }
        subMenuItems.push(
          <Menu.Item key="road-management-digital-twin" onClick={() => this.onClickOpenWindowRoad(roadManagementUrl + "#" + initJson, initJson)}>
            道路運営管理デジタルツイン
          </Menu.Item>);
        subMenuItems.push(
          <Menu.Item key="road-management-dashboard" onClick={() => this.onClickOpenWindowRoadDashboard(roadManagementDashboardUrl)}>
            ダッシュボード
          </Menu.Item>);
      }

      menuItems.push(
        <SubMenuRow
          key="project-submenu-road-management"
          icon="./icon/Road.svg"
          title="道路メニュー"
          collapsed={collapsed}
          subMenuItems={subMenuItems}
          {...this.props}
        />
      );
    }

    // インフラテクターコア
    const isDisplayInfratectorCore = accessibleScreenList.includes('infratectorCore');
    if (isDisplayInfratectorCore) {
      const subMenuItems = [];

      if (!isReadOnly) {
        subMenuItems.push(
          <Menu.Item key="video-upload">
            <Link to="/VideoUpload">動画アップロード</Link>
          </Menu.Item>
        );
        routes.push(
          <Route key="video-upload-key" path='/VideoUpload' render={() => (
            <VideoUpload
              setLoadingFlag={this.setLoadingFlag}
            />
          )} />
        );
        subMenuItems.push(
          <Menu.Item key="correction-image">
            <Link to="/CorrectionImage">静止画補正</Link>
          </Menu.Item>
        );
        routes.push(
          <Route key="correction-image-key" path='/CorrectionImage' render={() => (
            <CorrectionImage
              setLoadingFlag={this.setLoadingFlag}
            />
          )} />
        );
      }
      subMenuItems.push(
        <Menu.Item key="inspection-results-acquisition">
          <Link to="/InspectionResultsAcquisition">点検結果取得</Link>
        </Menu.Item>
      );
      routes.push(
        <Route key="inspection-results-acquisition-key" path='/InspectionResultsAcquisition' render={() => (
          <InspectionResultsAcquisition />
        )} />
      );

      menuItems.push(
        <SubMenuRow
          key="project-submenu-infratector-core"
          icon="./icon/Road.svg"
          title="メニュー"
          collapsed={collapsed}
          subMenuItems={subMenuItems}
          {...this.props}
        />
      );
    }

    // フッターメニュー
    const footerMenu = (
      <Menu mode="inline" theme={"dark"}>
        {/* {manualMenu} */}
        <Menu.Item key="logout">
          <Popconfirm
            placement="topLeft"
            title={LOGOUT_CONFIRM_MESSAGE}
            onConfirm={this.onClickLogOut}
            okText="OK"
            cancelText="Cancel"
          >
            <div className="footer">
              <MenuIcon src="./icon/Logout.png" alt="ログアウト" />
              <MenuText title='ログアウト' collapsed={collapsed} />
            </div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return { menuItems, routes, footerMenu };
  }

  /**
   * component render前の処理
   */
  async componentDidMount() {
    const menuKey = this.getMenuKey();

    const cache = await caches.open('sdf-preset-ap-func');
    const cacheData = await cache.match('/authFunction');
    const data = await cacheData.json();

    this.setState({
      selectedMenuKey: menuKey,
      authorization: data.PermissionFunction,
    });
  }

  /**
   * component破棄時の処理
   * ストレージに格納した情報を削除
   */
  componentWillUnmount() {
    removeStorageData();
  }

  render() {
    const { collapsed } = this.state;
    const { menuItems, routes, footerMenu } = this.generateMenuItemsWithRoute();

    return (
      <div style={{ width: "100%" }}>

        <Spin spinning={this.state.loadingFlag} size="large" tip="Loading..." style={{ marginTop: "10%" }}>
          <Layout style={{ height: '100vh', overflow: 'auto' }}>
            <HashRouter>

              <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{ height: '100vh', whiteSpace: "nowrap" }} width={250} className="site-layout-background">
                <Content style={{ overflow: 'auto', height: '88%' }}>

                  <Menu mode="inline" theme={"dark"} selectedKeys={[this.state.selectedMenuKey]} onClick={this.onClickMenu} defaultOpenKeys={['']}>
                    {menuItems && Object.keys(menuItems).map((item) => menuItems[item])}
                  </Menu>

                </Content>
                <Footer style={{ bottom: "60px", position: "absolute", width: "100%", padding: "0", background: "#001529" }}>
                  {footerMenu}
                </Footer>
              </Sider>
              <div id="oss-link-div">
                <Button type='link' onClick={this.onVersionClick}>OSS</Button>
              </div>
              <Layout style={{ height: '100%', overflow: 'auto' }}>
                {routes && Object.keys(routes).map((item) => routes[item])}
              </Layout>
            </HashRouter>

            <Modal
              visible={this.state.isModal}
              centered={true}
              closable={false}
              footer={null}
              onCancel={this.onCancelModal}
              style={{ margin: "0", padding: "0", textAlign: "left" }}
              width={1000}
              bodyStyle={{ width: "1000px", height: "948px" }}
            >
              <object aria-label={"OSS一覧"} data={PATH_OF_OSS_LIST} type={Text} width='100%' height='100%' />
            </Modal>

          </Layout>

        </Spin>

      </div>
    );
  }
}

export default Main;
