import React, { useEffect, useState } from 'react';
import {
  Typography,
  Pagination,
  Radio,
  Row,
  Col,
  message,
} from 'antd';
import './InspectionResultsAcquisition.css';
import ImageInfoHeader from '../Common/ImageInfoHeader/ImageInfoHeader';
import ImageTable from '../Common/ImageTable/ImageTable';
import SelectImage from '../Common/SelectImage/SelectImage';
import { EP_PATH_INFRATECTOR_INSPECTION_LIST } from '../../utils/AWS/EndpointPath';
import { postApiGateway } from '../../utils/AWS/ApiGateway';

const { Title } = Typography;

const COLMUN = 100;
const A_BUTTON = 'A';
const B_BUTTON = 'B';
const C_BUTTON = 'C';

/**
 * 画像取得パラメータ
 * @property Place 設置場所
 * @property UnitNo 号機
 * @property DateTime 日時
 * @property Position 部位
 * @property Group グループ
 * @property PageNum ページ数
 */
export type getImagesProps = {
  Place: string,
  UnitNo: string,
  DateTime: string,
  Position: string,
  Group: string,
  PageNum: number
}

/**
 * レスポンス取得パラメータ
 * @property imgList 画像リスト
 * @property totalImgNum 総ページ数
 */
export type getInspectionJson = {
  body: {
    imgList: string[],
    totalImgNum: number,
  }
}

/**
 * 点検結果取得画面
 */
const InspectionResultsAcquisition: React.FC = () => {
  const [selGroup, setSelGroup] = useState(['']);
  const [totalPage, setTotalPage] = useState(Number);
  const [currentPage, setCurrentPage] = useState(1);
  const [onClickGetImage, setOnClickGetImage] = useState(Boolean);
  const [displayDateTime, setDisplayDateTime] = useState('');
  const [searchInfo, setSearchInfo] = useState<getImagesProps>({
    Place: '',
    UnitNo: '',
    DateTime: '',
    Position: '',
    Group: '',
    PageNum: 1,
  });

  /**
   *  点検画像ボタン押下時
   * @param e
   */
  /* eslint @typescript-eslint/no-explicit-any: 0 */
  const onClicSearchImage = (e: any) => {
    setOnClickGetImage(true);

    setSearchInfo({
      Place: e.Place,
      UnitNo: e.UnitNo,
      DateTime: e.DateTime,
      Position: e.Position,
      Group: '',
      PageNum: 1,
    });
    setDisplayDateTime(e.DisplayDateTime);
  };

  /**
   * グループボタン押下時
   * @param button
   * @param imgUrl
   */
  const onClickRadio = (button: string) => {
    setSelGroup([]);
    setSearchInfo({ ...searchInfo, Group: button, PageNum: 1 });
    setCurrentPage(1);
  };

  /**
   * ページボタン押下時
   * @param page
   */
  const onClickPage = (page: number) => {
    setSelGroup([]);
    setSearchInfo({ ...searchInfo, PageNum: page });
    setCurrentPage(page);
  };

  /**
   * APIGatewayと通信し、画像ファイルと総ページ数取得
   * @param 画像検索用パラメータ
   */
  async function callApi(data: getImagesProps) {
    try {
      await postApiGateway<getImagesProps, getInspectionJson>(
        EP_PATH_INFRATECTOR_INSPECTION_LIST, data,
      ).then((e) => {
        if (e.body !== undefined) {
          setSelGroup(e.body.imgList);
          setTotalPage(e.body.totalImgNum);
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message);
      }
    }
  }

  /**
   * APIの呼び出し
   */
  useEffect(() => {
    const data: getImagesProps = {
      Place: searchInfo.Place,
      UnitNo: searchInfo.UnitNo,
      DateTime: searchInfo.DateTime,
      Position: searchInfo.Position,
      Group: searchInfo.Group,
      PageNum: searchInfo.PageNum,
    };

    if (searchInfo.Group !== '') {
      callApi(data);
    }
  }, [searchInfo]);

  return (
    <div className="inspection-results-acquisition-container">
      <div className="inspection-results-acquisition-title">
        <Title level={4}>点検結果取得</Title>
      </div>
      {onClickGetImage
        || (
          <SelectImage
            screenId="InspectionResultsAcquisitions" //  遷移元画面
            onClickSearchImage={(e) => (onClicSearchImage(e))}
          />
        )}
      {onClickGetImage
        && (
          <div className="image-table-wrapper">
            <Row type="flex" align="bottom">
              <Col span={1} />
              <Col span={10}>
                <ImageInfoHeader
                  filmingLocation={searchInfo.Place}
                  subjectOfPhotographing={searchInfo.UnitNo}
                  date={displayDateTime}
                  shootingSite={searchInfo.Position}
                />
              </Col>
              <Col span={6}>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button
                    value={A_BUTTON}
                    onClick={() => onClickRadio(A_BUTTON)}
                    className="radio-button"
                  >
                    グループA
                  </Radio.Button>
                  <Radio.Button
                    value={B_BUTTON}
                    onClick={() => onClickRadio(B_BUTTON)}
                    className="radio-button"
                  >
                    グループB
                  </Radio.Button>
                  <Radio.Button
                    value={C_BUTTON}
                    onClick={() => onClickRadio(C_BUTTON)}
                    className="radio-button"
                  >
                    グループC
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            {searchInfo.Group
              && (
                <>
                  <ImageTable
                    imgGroup={selGroup}
                    imageIndex={(currentPage - 1) * COLMUN}
                  />

                  <Pagination
                    total={totalPage}
                    defaultPageSize={COLMUN}
                    current={currentPage}
                    onChange={(page) => (onClickPage(page))}
                    size="small"
                  />
                </>
              )}
          </div>
        )}
    </div>
  );
};

export default InspectionResultsAcquisition;
