/**
 * エラーメッセージの表示時間(秒)
 * @link sdf-docs:03_画面仕様書/03-02_画面仕様書_設備管理_詳細表示・変更.xlsx
 */
export const POPUP_ERROR_MESSAGE_DISPLAY_TIME = 10;

/** プロジェクト作成時の入力チェックエラーメッセージ(必須入力) */
export const VALIDATE_ERROR_PROJECT_NAME_REQUIRE =
  "学習プロジェクト名は必須入力です。";
/** プロジェクト作成時の入力チェックエラーメッセージ(文字数) */
export const VALIDATE_ERROR_PROJECT_NAME_TOO_LONG =
  "学習プロジェクト名は1文字以上、140文字以内でなければなりません。";
/** プロジェクト作成時の入力チェックエラーメッセージ(重複) */
export const VALIDATE_ERROR_PROJECT_NAME_ALREADY_EXISTS =
  "対象の学習プロジェクト名は既に存在しています。";

/** ファイル追加失敗メッセージ */
export const FAILED_ADD_FILE = "：ファイルの追加が失敗しました。";
/** ファイル追加後のリロードボタン押下要求メッセージ */
export const INFORMATION_REQUEST_RELOAD_AFTER_ADD_FILE =
  "追加したファイルが画面に反映されない場合があります。リロードボタンを押してください。";
/** ファイル追加時の同時追加数チェックエラーメッセージ前半 */
export const VALIDATE_ERROR_NUMBER_OF_SIMULTANEOUS_ADDITION_OF_FILE_FIRST =
  "ファイルの同時追加は ";
/** ファイル追加時の同時追加数チェックエラーメッセージ後半 */
export const VALIDATE_ERROR_NUMBER_OF_SIMULTANEOUS_ADDITION_OF_FILE_SECOND =
  " ファイルまでです。";
/** ドラッグアンドドロップでファイルを追加時の警告メッセージ */
export const WARNING_DRAG_AND_DROP_FILE =
  "本画面からファイル追加は出来ません。";
/** ファイル削除時の警告タイトル */
export const WARNING_DELETE_FILE_TITLE = "警告";

/** 画像削除の完了メッセージ */
export const IMAGE_DELETION_COMPLETED_SUCCESSFULLY = "画像を削除しました。";
/** 画像削除時のチェックボックス未選択エラー */
export const VALIDATE_ERROR_UNSELECTED_IMAGE = "画像が選択されていません。";
/** 画像削除時の学習プロジェクト登録画像詳細エラーメッセージ(File名+''+PJ名+'') */
export const VALIDATE_ERROR_EXIST_IN_LEARNING_PJ_AFTER_FILE =
  " は、学習プロジェクト「";
export const VALIDATE_ERROR_EXIST_IN_LEARNING_PJ_AFTER_PJ =
  "」に登録されています。";
/** 画像削除時の学習プロジェクト登録画像エラーメッセージ */
export const VALIDATE_ERROR_EXIST_IN_LEARNING_PJ =
  "学習プロジェクトに登録されている画像があるため、削除はキャンセルしました。";

/** 学習プロジェクトのJPEGファイル登録成功メッセージ */
export const SUCCESS_ADD_IMAGE_TO_PJ =
  "学習プロジェクトにJPEGファイルを登録しました。";
/** 学習プロジェクトのJPEGファイル登録失敗メッセージ */
export const FAILED_ADD_IMAGE_TO_PJ = "JPEGファイルを選択してください。";
/** 学習プロジェクトのJPEGファイル登録警告メッセージ */
export const WARNING_ADD_IMAGE_TO_PJ =
  SUCCESS_ADD_IMAGE_TO_PJ +
  "ただしJPEG以外のファイルは学習プロジェクトに登録できませんでした。";
/** 学習プロジェクトのJPEGファイル追加でファイル未選択エラーメッセージ */
export const VALIDATE_ERROR_NO_SELECT_FILE_ADD_IMAGE_TO_PJ =
  "ファイルを選択してください。";
/** 学習プロジェクトのJPEGファイル追加で学習プロジェクト未選択エラーメッセージ */
export const VALIDATE_ERROR_NO_SELECT_PJ_ADD_IMAGE_TO_PJ =
  "学習プロジェクトを選択してください。";

/** クラス作成時の入力チェックエラーメッセージ(文字種別) */
export const VALIDATE_ERROR_CLASS_NAME_CHARACTER_TYPE =
  "半角英数字のみ入力してください。";
/** クラス作成時の入力チェックエラーメッセージ(文字数) */
export const VALIDATE_ERROR_CLASS_NAME_TOO_LONG =
  "30文字以内で入力してください。";
/** クラス作成時の入力チェックエラーメッセージ(重複) */
export const VALIDATE_ERROR_CLASS_NAME_ALREADY_EXISTS =
  "対象のクラス名は既に存在しています。";
/** クラス削除時の警告タイトル */
export const WARNING_DELETE_CLASS_TITLE = "警告";
/** クラス削除時の警告メッセージ */
export const WARNING_DELETE_CLASS_MESSAGE =
  "アノテーション情報も削除されますがよろしいでしょうか？";

/** 学習プロジェクトから画像削除時の警告タイトル */
export const WARNING_DELETE_IMAGE_FROM_PJ_TITLE = WARNING_DELETE_CLASS_TITLE;
/** 学習プロジェクトから画像削除時の警告メッセージ */
export const WARNING_DELETE_IMAGE_FROM_PJ_MESSAGE =
  WARNING_DELETE_CLASS_MESSAGE;

/** ポータル画面のお知らせ読み込み失敗メッセージ */
export const ERROR_READ_NOTIFICATION = "お知らせを読み込めませんでした。";
/** ログイン時のエラーメッセージ(権限無し) */
export const VALIDATE_ERROR_NO_AUTHORITY =
  "権限がないため、ログインできません。";
/** ログイン時のエラーメッセージ(テナント名未入力) */
export const VALIDATE_ERROR_TENANT_NAME_REQUIRE =
  "テナント名を入力してください。";
/** トークンの有効期限切れメッセージ */
export const WARNING_TOKEN_TIMEOUT = "認証の有効期限が切れました。";
export const WARNING_TOKEN_TIMEOUT_WITH_SIGN_OUT = "認証が切れました。";

/** タスク停止失敗時のエラーメッセージ */
export const VALIDATE_ERROR_TASK_STOP =
  "タスクの停止に失敗しました。エラーコード: ";
/** タスク実行失敗時のエラーメッセージ */
export const VALIDATE_ERROR_TASK_START =
  "タスクの実行に失敗しました。エラーコード: ";

/** 学習プロジェクト削除時の警告タイトル */
export const WARNING_DELETE_LEARNING_PROJECT_TITLE = WARNING_DELETE_CLASS_TITLE;
// /** 学習プロジェクト削除時の確認ダイアログメッセージ */
export const CONFIRM_DELETE_LEARNING_PROJECT_MESSAGES = [
  "学習プロジェクトを削除してよろしいですか？",
  "学習プロジェクトに紐づく情報も削除されます。",
];
/** 学習プロジェクト削除時の完了メッセージ(成功) */
export const SUCCESS_DELETE_LEARNING_PROJECT =
  "学習プロジェクトの削除が完了しました。";

/** 学習モデル作成タスク登録完了メッセージ */
export const SUCCESS_ADD_LEARNING_MODEL_TASK =
  "学習モデル作成タスクが正常に登録されました。";
/** 学習モデル作成タスク登録エラーメッセージ */
export const ERROR_ADD_LEARNING_MODEL_TASK =
  "学習モデル作成タスクの登録に失敗しました。エラーコード:";
/** 画像未選択時のエラーメッセージ */
export const VALIDATE_ERROR_NO_SELECT_IMAGE = "対象の写真を選択してください。";
/** 学習モデル作成時の入力チェックエラーメッセージ(学習モデル名 必須入力) */
export const VALIDATE_ERROR_MODEL_NAME_REQUIRE = "学習モデル名は必須入力です。";
/** 学習モデル作成時の入力チェックエラーメッセージ(パラメータ 必須入力) */
export const VALIDATE_ERROR_PARAMETER_REQUIRE = "パラメータは必須入力です。";
/** 学習モデル作成時の入力チェックエラーメッセージ(アノテーション情報ファイル存在確認) */
export const VALIDATE_ERROR_NO_EXISTS_ANNOTATION_FILE =
  "アノテーション情報ファイルが存在しません。";

/** AI検出タスク登録完了メッセージ */
export const SUCCESS_ADD_DETECT_TASK = "AI検出タスクが正常に登録されました。";
/** AI検出タスク作成時の入力チェックエラーメッセージ(学習プロジェクト/モデル 必須入力) */
export const VALIDATE_ERROR_LEARNING_PROJECT_WITH_MODEL_REQUIRE =
  "学習モデルを選択してください。";
/** AI検出タスク作成時の入力チェックエラーメッセージ(クラス 必須入力) */
export const VALIDATE_ERROR_CLASSES_REQUIRE = "クラスを選択してください。";
/** AI検出タスク作成時の入力チェックエラーメッセージ(クラス数) */
export const VALIDATE_ERROR_CLASSES_OVER = "5つのクラスまで選択してください。";
/** AI検出タスク作成時の入力チェックエラーメッセージ(検出タスク名 必須入力) */
export const VALIDATE_ERROR_DETECT_TASK_NAME_REQUIRE =
  "検出タスク名を入力してください。";
/** AI検出タスク作成時の入力チェックエラーメッセージ(検出タスク名 指定不可文字) */
export const VALIDATE_ERROR_DETECT_TASK_NAME_SPECIFY_NON_TEXT =
  "利用できない文字が含まれています（禁止文字の詳細はマニュアル参照）";
/** AI検出タスク登録エラーメッセージ */
export const ERROR_ADD_AI_DETECT_TASK =
  "AI検出タスクの登録に失敗しました。エラーコード: ";

/** タスク削除失敗時のメッセージ */
export const ERROR_TASK_DELETE = "タスクの削除に失敗しました。エラーコード: ";
/** パラメータファイル読込失敗時のメッセージ */
export const ERROR_LOAD_PARAMETER_FILE =
  "一部データ取得に失敗しました。リロードしてください。";
/** 学習動作中のタスク削除警告メッセージ */
export const WARNING_DELETE_TASK_DURING_LEARNING =
  "学習動作中は実行中/実行待ちタスクは削除できません。";
/** 検出動作中のタスク削除警告メッセージ */
export const WARNING_DELETE_TASK_DURING_DETECTION =
  "検出動作中は実行中/実行待ちタスクは削除できません。";

/** 点検結果一覧読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_INSPECTION_RESULTS =
  "点検一覧を読み込むことができませんでした。";

/** 点検結果登録・更新画面 対象設備読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGEMENT_FACILITIES =
  "対象設備の読み込みに失敗しました。";
/** 点検結果登録完了メッセージ */
export const SUCCESS_SAVE_INSPECTION_RESULT =
  "点検結果が正常に保存できました。";
/** 点検結果登録失敗メッセージ */
export const ERROR_SAVE_INSPECTION_RESULT = "点検結果が保存できませんでした。";
/** 点検結果登録・更新時の入力チェックエラーメッセージ(名前) */
export const VALIDATE_ERROR_NAME_REQUIRE = "名前を入力してください。";
/** 点検結果登録・更新時の入力チェックエラーメッセージ(開始年月日) */
export const VALIDATE_ERROR_START_DATE_REQUIRE =
  "開始年月日を選択してください。";
/** 点検結果登録・更新時の入力チェックエラーメッセージ(終了年月日) */
export const VALIDATE_ERROR_END_DATE_REQUIRE = "終了年月日を選択してください。";
/** 点検結果登録・更新時の入力チェックエラーメッセージ(点検者名) */
export const VALIDATE_ERROR_INSPECTOR_NAME_REQUIRE =
  "点検者名を入力してください。";
/** 点検結果登録・更新時の入力チェックエラーメッセージ(対象管理設備) */
export const VALIDATE_ERROR_FACILITY_REQUIRE =
  "対象管理設備を選択してください。";

/** 管理設備一覧読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGED_FACILITIES =
  "管理設備一覧の読み込みに失敗しました。";
/** 教師データ一覧読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_TEACHER_DATA =
  "教師データ一覧の読み込みに失敗しました。";
/** ダッシュボード　管理設備読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGED_FACILITY =
  "管理設備一覧の読み込みに失敗しました。";
/** ダッシュボード　タイムシリーズ読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGED_FACILITY_TIMESERIES =
  "タイムシリーズの読み込みに失敗しました。";
/** ダッシュボード　データポイント読み込み失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGED_FACILITY_DATAPOINTS =
  "データポイントの読み込みに失敗しました。";
/** ダッシュボード　アセット数カウント失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGED_FACILITY_ASSETS_COUNT =
  "アセット数のカウントに失敗しました。";
/** ダッシュボード　ファイル数カウント失敗時のエラーメッセージ */
export const ERROR_LOAD_MANAGED_FACILITY_FILES_COUNT =
  "ファイル数のカウントに失敗しました。";

/** 管理設備・設備 登録／更新ダイアログ 管理設備・設備登録完了メッセージ */
export const SUCCESS_SAVE_FACILITY = "[target]の[process]に成功しました。";
/** 管理設備・設備 登録／更新ダイアログ 管理設備・設備登録失敗メッセージ */
export const ERROR_SAVE_FACILITY = "[target]の[process]に失敗しました。";
/** 管理設備・設備 登録／更新ダイアログ 入力チェックエラーメッセージ(名前未入力) */
export const VALIDATE_ERROR_FACILITY_NAME_REQUIRE =
  "設備名を入力してください。";
/** 管理設備・設備 登録／更新ダイアログ 入力チェックエラーメッセージ(ルートアセット未選択) */
export const VALIDATE_ERROR_ROOT_ASSET_SELECT_REQUIRE =
  "ルートアセットを選択してください。";
/** 管理設備・設備 登録／更新ダイアログ 親設備読み込み失敗 */
export const ERROR_LOAD_PARENT_FACILITY = "親設備が取得できませんでした。";
/** 管理設備・設備 登録／更新ダイアログ ルートアセット読み込み失敗 */
export const ERROR_LOAD_ROOT_ASSET = "ルートアセットの読み込みに失敗しました。";
/** 管理設備・設備 登録／更新ダイアログ 詳細情報入力チェックエラーメッセージ（システム予約文字列） */
export const VALIDATE_ERROR_METADATA_SYSTEM_RESERVED =
  "システム予約文字列のため使用できません。";
/** 管理設備・設備 登録／更新ダイアログ 詳細情報入力チェックエラーメッセージ */
export const VALIDATE_ERROR_METADATA_REQUIRE = `[title]を入力してください。`;
/** 管理設備・設備 登録／更新ダイアログ 詳細情報(key)入力文字数チェックエラーメッセージ */
export const VALIDATE_ERROR_METADATA_KEY_TOO_LONG =
  "項目は[key_limit]文字以内でなければなりません。";
/** 管理設備・設備 登録／更新ダイアログ 詳細情報(value)入力文字数チェックエラーメッセージ */
export const VALIDATE_ERROR_METADATA_VALUE_TOO_LONG =
  "値は[value_limit]文字以内でなければなりません。";
/** 管理設備・設備 登録／更新ダイアログ 詳細情報削除時の確認ダイアログメッセージ */
export const CONFIRM_DELETE_METADATA_MESSAGE =
  "本当に削除してもよろしいですか？";
/** 管理設備・設備 登録／更新ダイアログ 詳細情報上限エラーメッセージ */
export const ERROR_ADD_METADATA_LIMIT =
  "詳細情報の追加はできません。詳細情報は100件までです。";
/** 管理設備・設備 登録／更新ダイアログ 詳細情報入力チェックエラーメッセージ（詳細情報(key)重複） */
export const VALIDATE_ERROR_METADATA_ALREADY_USED =
  "既に使用されている項目のため使用できません。";

/** 設備階層読み込み失敗時エラーメッセージ */
export const ERROR_LOAD_FACILITY_ASSET_TREE =
  "設備階層の読み込みに失敗しました。";

/** ログアウトボタン押下時確認メッセージ */
export const LOGOUT_CONFIRM_MESSAGE = "ログアウトします。よろしいですか？";

/** アンサンブルモデル作成 アンサンブルモデル名未入力時確認メッセージ */
export const ENSEMBLE_MODEL_NAME_UNENTERED =
  "アンサンブルモデル名を入力してください。";
/** アンサンブルモデル作成 アンサンブルモデル名重複時メッセージ */
export const ENSEMBLE_MODEL_NAME_DUPLICATION =
  "アンサンブルモデル名が重複しています。";
/** アンサンブルモデル作成 アンサンブルクラス名入力後、OKボタン未押下時メッセージ */
export const ENSEMBLE_CLASS_NAME_OK_UNPRESSED =
  "アンサンブルクラス名を入力後、OKボタンを押してください。";
/** アンサンブルモデル作成 アンサンブルクラス名未入力時メッセージ */
export const ENSEMBLE_CLASS_NAME_UNENTERED =
  "アンサンブルクラス名を入力してください。";
/** アンサンブルモデル作成 アンサンブルクラス名重複時メッセージ */
export const ENSEMBLE_CLASS_NAME_DUPLICATION =
  "アンサンブルクラス名が重複しています。";
/** アンサンブルモデル作成 モデル未選択時メッセージ */
export const ENSEMBLE_MODEL_MODEL_UNSELECTED = "モデルを選択してください。";
/** アンサンブルモデル作成 RowCard削除時警告メッセージ */
export const ENSEMBLE_MODEL_DELETE_ROW_CARD =
  "アンサンブルクラスを１つ設定してください。";
/** アンサンブルモデル作成 RowCard追加時警告メッセージ */
export const ENSEMBLE_MODEL_PLUS_ROW_CARD =
  "5つのアンサンブルクラスまで設定してください。";
/** アンサンブルモデル作成 ParamCard削除時警告メッセージ */
export const ENSEMBLE_MODEL_DELETE_PARAM_CARD =
  "パラメーターを１つ設定してください。";
/** アンサンブルモデル作成 ListCard削除時警告メッセージ */
export const ENSEMBLE_MODEL_DELETE_LIST_CARD =
  "クラスデータを１つ設定してください。";

/** ファイル情報更新成功メッセージ */
export const SUCCESS_FILES_UPDATE = "ファイル情報の更新に成功しました。";
/** ファイル情報取得エラーメッセージ */
export const ERROR_FILES_RETRIEVE = "ファイル情報の取得に失敗しました。";
/** ファイル読み込みエラーメッセージ */
export const ERROR_FILES_GET_DL_URL = "ファイルの読み込みに失敗しました。";
/** ファイル情報更新エラーメッセージ */
export const ERROR_FILES_UPDATE = "ファイル情報の更新に失敗しました。";

/** ファイル情報更新エラーメッセージ */
export const ERROR_FILE_PREVIEW =
  "本ファイルはプレビューできません\nダウンロードして参照してください";

/** 画像読込エラーメッセージ */
export const ERROR_LOAD_IMAGE_FILE = "画像の読込に失敗しました。";

/** 権限エラーメッセージ*/
export const ERROR_NO_AUTH_MESSAGE = "権限がありません";
/** 点検内容新規登録権限エラーメッセージ */
export const ERROR_NO_ADD_SCANS_AUTH_MESSAGE =
  "権限が無いため点検内容の新規登録は行えません";
/** タスク優先度変更権限エラーメッセージ */
export const ERROR_NO_TASK_PRIORITY_CHANGE_AUTH_MESSAGE =
  "権限が無いためタスクの優先度変更は行えません";

/** 詳細情報検索画面　詳細情報登録失敗メッセージ */
export const ERROR_SAVE_SEARCH_METADATA_TABLE =
  "未入力の条件があるため保存できません。";
/** 詳細情報検索画面 詳細情報入力チェックエラーメッセージ */
export const VALIDATE_ERROR_SEARCH_METADATA_REQUIRE = `[title]を入力してください。`;

/** データ登録画面 情報入力不足エラーメッセージ */
export const INSUFFICIENT_INPUT_OF_INFORMATION =
  "未設定の項目が存在しています。すべての項目を設定してください。";
/** データ登録画面 フォーマットエラーメッセージ */
export const REGISTRATION_FORMAT_ERROR =
  "選択された登録データ種別とアップロードされたファイルが異なります";
/** データ登録画面 バリデーションエラーメッセージ */
export const VALIDATIONS_CHECK_ERROR =
  "ファイルに不正な値が設定されているためアップロードできません";
/** データ登録画面 データ件数バリデーションエラーメッセージ */
export const VALIDATIONS_LIMIT_CHECK_ERROR =
  "一度に登録可能な件数（200サイト）を超えています。ファイルを修正して再登録してください。";
/** データ登録画面 管理No未入力バリデーションエラーメッセージ */
export const VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR =
  "管理Noが未入力の行があります。";
/** データ登録画面 管理No存在確認バリデーションエラーメッセージ */
export const VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO =
  "存在しない管理Noが設定されているため登録できません。ファイルを修正して再登録してください";
/** データ登録画面 ファイル種別エラーメッセージ */
export const FILE_TYPE_CHECK_ERROR =
  "ファイルの種類が異なります。正しいファイルをアップロードしてください。";
/** データ登録画面 ファイルアップロード失敗メッセージ */
export const ERROR_FILES_UPLOAD =
  "ファイルのアップロードに失敗しました。時間をおいてもう一度試してください。";
/** データ登録画面 ファイルアップロード成功メッセージ */
export const SUCCESS_FILES_UPLOAD = "ファイルが正常にアップロードされました";
/** データ登録画面 ファイル上書き確認メッセージ */
export const FILE_UPDATE_CHECK = [
  "同一ファイル名、同一タグのファイルが登録されています。",
  "上書きしてよろしいですか？",
];

/** 発電量ステータス一覧画面 検索結果上限超えエラーメッセージ */
export const ERROR_SEARCH_RESULT_OVER_LIMIT =
  "検索結果件数が1000件を超えています。件数が1000件以下になるよう検索条件を修正してください。";
/** 発電量ステータス一覧画面/財務帳票登録画面 検索条件未入力エラーメッセージ */
export const ERROR_NOT_ENTERED_SEARCH_CONDITION =
  "検索条件を1つ以上選択してください。";

/** 財務帳票登録画面 必須項目未入力エラーメッセージ */
export const ERROR_FINANCIAL_REPORT_VALIDATION_REQUIRE =
  "財務帳票ファイル、サイト、年度は必須入力です。";
/** 財務帳票登録画面 検索結果上限超えエラーメッセージ */
export const ERROR_SEARCH_RESULT_OVER_300 =
  "検索結果件数が300件を超えています。件数が300件以下になるよう検索条件を修正してください。";
/** 財務帳票登録画面 ファイル削除確認メッセージ */
export const CONFIRM_DELETE_FINANCIAL_REPORT_FILE =
  "ファイルを削除してよろしいですか？";
/** 財務帳票登録画面　ファイル削除時の完了メッセージ(成功) */
export const SUCCESS_DELETE_FINANCIAL_REPORT_FILE =
  "ファイルの削除が完了しました。";
/** 動画アップロード アップロード確認メッセージ */
export const CONFIRM_VIDEO_UPLOAD =
  "動画アップロードを行います。よろしいですか";
/** 動画アップロード アップロード完了メッセージ */
export const SUCCESS_VIDEO_UPLOAD = "ファイルアップロードが完了しました。";
/** 動画アップロード 入力チェックエラーメッセージ(未入力) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_REQUIRE = "未入力の項目があります。";
/** 動画アップロード 入力チェックエラーメッセージ(文字種) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_CHARACTER_TYPE =
  "使用できない文字の入力があります。";
/** 動画アップロード 入力チェックエラーメッセージ(桁数) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_LENGTH = "最大桁数を超過しています。";
/** 動画アップロード 動画ファイル 入力チェックエラーメッセージ(ファイル種類) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_FILE_TYPE =
  "MP4またはMOV形式の動画ファイルを選択してください。";
/** 動画アップロード 動画ファイル 入力チェックエラーメッセージ(ファイルサイズ) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SIZE =
  "ファイルサイズが大きすぎます。ファイルが1GB以下になるよう分割して再度実施してください。";
/** 動画アップロード 動画ファイル 入力チェックエラーメッセージ(重複) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_FILE_DUPLICATE =
  "指定されたファイルは既にアップロードされております。確認してください。";
/** 動画アップロード 動画ファイル 入力チェックエラーメッセージ(システムエラー) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_FILE_SYSTEM_ERROR =
  "システム異常が発生しました。システム管理者へご連絡ください。";
/** 動画アップロード 動画ファイル 入力チェックエラーメッセージ(ファイル名　重複) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_FILE_DUPLICATE_FILENAME =
  "指定されたファイルの名称が不適切です。正しいファイル名を指定して再度実行してください。";
/** 動画アップロード 動画ファイル 入力チェックエラーメッセージ(ファイル名　半角英数字) */
export const VALIDATE_ERROR_VIDEO_UPLOAD_FILE_CONTAINS_FULLWIDTH_FILENAME =
  "ファイル名は半角英数字のみを入力してください。";
export const D1E08 =
  "入力された内容が正しくありません。入力内容を見直してください。";
/**静止画補正画面 確認結果登録ボタン押下時メッセージ*/
export const M1001 =
  "確認結果を登録します。よろしいですか ？";
/**静止画補正画面 確認結果登録処理完了メッセージ*/
export const M1002 = "確認結果を登録しました。";
/** 静止画補正画面 静止画取得ボタン押下時未選択エラーメッセージ*/
export const M1E01 =
  "最低でも1件以上選択してください。";
/**静止画補正画面 静止画分割未完メッセージ */
export const M1E02 =
  "静止画分割が完了していません。完了後に再度実施してください。";
/**点検画像取得画面 点検画像存在確認エラーメッセージ */
export const M2E01 = "入力された内容に該当する点検画像がありません。";
